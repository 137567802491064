import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const CoatingFinishesPage = () => {
  return (
    <Layout hero={<HeroImage title="Coatings & Finishes" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  A variety of surface finishes are available for use in the fire protection industry. 
                  All sprinkler and hydrant pipe products are designed to comply with AS 4118.2.1 and AS 2419.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/coatings-finishes-available">
                    <h2 className="mb-4">COATING & FINISHES</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/allgal" className="mb-2">
                        ALLGAL® Coating
                      </Link>
                      <p>Whether it's for a shade structure, cattle yard, bus shelter, floor framing or fence post.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/hot-dipped-galvanised-coatings-finishes" className="mb-2">
                        Hot Dipped Galvanised Coatings & Finishes
                      </Link>
                      <p>Hot Dip Galvanised finished products are perfect for jobs that require longer lasting protection against all weather and all conditions.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/liquid-allgal-coating" className="mb-2">
                        Liquid ALLGAL Coating
                      </Link>
                      <p>Liquid ALLGAL® is a fast drying, high solids and high performance zinc-rich epoxy coating.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/smartcote" className="mb-2">
                        SMARTCOTE
                      </Link>
                      <p>SMARTCOTE® offers a number of advantages over oil based steel coatings and is used in a broad range of applications including welding, galvanising.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/standard-finishes" className="mb-2">
                        Standard Finishes
                      </Link>
                      <p>Orrcon offers a range of other Standard Finishes like Mill Finish, NOPC, MSGB and more...</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default CoatingFinishesPage

export const Head = () => <title>Coatings & Finishes</title>
